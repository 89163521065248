@import '~bootstrap/scss/bootstrap';


html {
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  background-image: url('../public/bg-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header.header {
  text-align: center;

  img {
    max-height: 250px;
  }
}

.search-bar {
  button {
    font-size: 0;
    svg{
      width: 20px;
      height: 20px;
    }
  }
}


.autocomplete-suggestions {
  position: absolute;
  z-index: 1000;
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.suggestion-item {
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}

.content-wrapper {
  .content {
    position: relative;
  }
}


html[lang="ar"] {
  direction: rtl;
  // other styles for Arabic language

  .search-bar {
    input {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;

      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    button {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
    }

  }

  .autocomplete-suggestions {
    padding-right: 0;
    padding-left: 40px;
  }
}


.description {
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 24px;
}

.instagram-link {
  font-size: 0;
  margin: 10px;

  color: red;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #000;
  }

  svg {
    font-size: 28px;
  }
}

#root {
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;

  > .container {
    background-color: rgba(229, 229, 240, .8);
    backdrop-filter: saturate(180%) blur(2px);
    padding-top: 15px;
    border-radius: 15px;
  }
}
